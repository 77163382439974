<template>
    <div>
		<b-modal ref="modalAddCross" size="xl" hide-footer @show="init_component">
			<template v-slot:modal-title>
				<template>
					{{ $t("gynecologie.add_cross") }}
				</template>
			</template>

			<div class="col-12">
				<div class="form-group">
					<label for="modeltype_label">{{ $t('monte.jument') }} <sup>*</sup></label>
					<e-select
						v-model="mare"
						id="horse_id"
						track-by="horse_id"
						label="horse_nom"
						:placeholder="labelTitleMares"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="mares"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:sortable="false"
					>
						<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="form-group" v-if="mare">
					<label for="horses" class="col-form-label">{{ $t('monte.etalon') }} <sup>*</sup></label>
					<e-select
						v-model="stallion"
						id="horse_id"
						track-by="horse_id"
						label="horse_nom"
						:placeholder="labelTitleStallions"
						:deselectLabel="deselectLabel"
						:selectedLabel="selectedLabel"
						:options="stallions"
						:searchable="true"
						:allow-empty="false"
						:show-labels="false"
						:sortable="false"
					>
						<template slot="first" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>

				<div class="box">
					<p>{{ $t('monte.mares_without_stallion') }}</p>
					<CustomTable
						id_table="mares_without_stallion"
						:items="items"
						:busy.sync="table_busy"
						primaryKey="horse_id"
						:hrefsRoutes="config_table_hrefs"
						:hide_if_empty="true"
						:checkboxes="false"
						@row-select="selectMare"
					/>
				</div>

				<b-button class="mt-3 btn-block rounded-pill" variant="primary" @click="checkForm">{{ $t("global.ajouter") }} <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse  class="mr-1"/></b-button>
			</div>
		</b-modal>
    </div>
</template>

<script type="text/javascript">
import Gynecologie from "@/mixins/Gynecologie.js"
import Horse from '@/mixins/Horse.js'
import _sortBy from "lodash/sortBy"

export default {
	name: "ModalAddCross",
	mixins: [Gynecologie, Horse],
	data () {
		return {
			processing: false,
			mare: null,
			stallion: null,
			mares: [],
			stallions: [],
			season_id: null,
			table_busy: false,
			config_table_hrefs: {
				'horse_nom': {
					routeUniqueName: 'HorseFichePlanning',
					routeName: 'HorseFichePlanning',
					params: {
						horse_id: 'horse_id'
					}
				}
			},
			items: [],

			deselectLabel: this.getTrad("global.press_enter_to_remove"),
			selectedLabel: this.getTrad("global.selected_label"),
			enter_to_select: this.getTrad("global.press_enter_to_select"),

			labelTitleMares: this.getTrad("monte.selectionnez_jument"),
			labelTitleStallions: this.getTrad("monte.selectionnez_etalon")
		}
	},
	methods: {
		async init_component() {
			this.processing = false
			const stallions = await this.getHorsesStallion()
			this.stallions = _sortBy(stallions, ['horse_nom'])
			const mares = await this.getMaresWithoutCross(this.season_id)
			this.mares = _sortBy(mares, ['horse_nom'])

			this.table_busy = true
			this.items = await this.getMaresInSeasonWithoutStallion(this.season_id)
			this.table_busy = false
		},
		openModal(season_id) {
			this.season_id = season_id
			this.$refs.modalAddCross.show()
		},
		closeModal() {
			this.$refs.modalAddCross.hide()
		},
		async checkForm() {
			this.processing = true
			if(this.season_id && this.mare && this.stallion) {
				const result = await this.addNewCross(this.season_id, this.mare, this.stallion)
				if(result) {
					this.successToast()
					await this.$sync.force(true)
					.then(() => {
						this.mare = null
						this.stallion = null
						this.$emit('submit')
					})
				}
				else {
					this.failureToast("error.LEP")
				}
				this.closeModal()
			}
			else if(!this.season_id) {
				this.failureToast('toast.error_season')
				this.closeModal()
			}
			else {
				this.failureToast('toast.all_inputs')
			}

			this.processing = false
		},
		selectMare(item) {
			this.mare = this.mares.find(m => m.horse_id == item.horse_id)
		}
	},
	components: {
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>